.contactBody {
    &__header{
        &-container {
            height: 80%;
            width: 100%;
            margin-bottom: 20px;
            position: relative;
        }
        &-img {
            object-fit: cover;
            width: 100%;
            height: 500px;
            display: inline-block;
            filter: blur(0.2rem);
        }
        &-title {
            width: 34%;
            height: 14%;
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            bottom: 0;
            transform: translate(-50%, -50%);
            font-weight: bold;
            font-size: 70px;
	    text-align: center;
            line-height: 80px;
            color: #fdfbfb;
        }
    }
    &__main {
        padding: 30px 30px 0 30px;
        margin-top: 20px;
    }
    &__title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
        color: #222;
        position: relative;
        padding-bottom: 30px;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 200px;
            height: 2px;
            background-color: #222;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        width: 50%;
    }
    &__item {
        padding-right: 8px;
        margin-bottom: 20px;
        &-img {
            width: 20px;
            height: 20px;
            transition: all 0.3s ease;
            filter: invert(55%) sepia(13%) saturate(18%) hue-rotate(316deg) brightness(88%) contrast(76%);
            &:hover {
                filter: invert(55%) sepia(96%) saturate(339%) hue-rotate(165deg) brightness(93%) contrast(96%);
            }
        }
    }
    &__container {
        margin-left: 20%;
        margin-right: 10%;
    }
    &__text {
        color: #a1a1a1;
        font-size: 15.5px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    &__email {
        color: #222;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 20px;
        padding-left: 25px;
        display: block;
        position: relative;
        width: 40%;
        &::before {
            content: "";
            position: absolute;
            background: url('../../images/telegram.svg');
            top: 4.5px;
            left: 0;
            width: 19px;
            height: 19px;
            filter: invert(12%) sepia(20%) saturate(15%) hue-rotate(81deg) brightness(104%) contrast(98%);
        }
    }
    &__form {
        &-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        &-input {
            margin-right: 7px;
            border-radius: 21px;
            padding: 4px 10px 4px 18px;
            background-color: transparent;
            border: .5px solid #222;
            width: 100%;
            margin-bottom: 10px;
            height: 40px;
            &::placeholder {
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 28px;
                color: #a1a1a1;
            }
            &--big {
                min-height: 140px;
                resize: none;
            }
        }
        &-btn {
            background-color: #222;
                border: none;
                border-radius: 21px;
                color: #fff;
                min-width: 160px;
                font-weight: 500;
                padding: 5px 9px;
                cursor: pointer;
                transition: all 0.7s ease;
		margin-bottom: 20px;
                &:hover {
                    background-color: #2E6A9F;
                    color: #fdfbfb;
                }
        }
    }
}

       
@media (max-width: 1170px) {
    .contactBody__header-title {
        width: 36%;
    }
    .contactBody__title {
        margin: 0 auto 30px auto;
        font-size: 25px;
    }
    .contactBody__main {
        padding: 30px 20px 0 20px;
        margin-top: 20px;
    }
}
@media (max-width: 1050px) {
    .contactBody__header-title {
        width: 42%;
        font-size: 60px;
    }
}
@media (max-width: 870px) {
    .contactBody__header-title {
        width: 48%;
    }
}
@media (max-width: 800px) {
    .contactBody__header-title {
        width: 54%;
    }
}
@media (max-width: 680px) {
    .contactBody__header-title {
        width: 60%;
    }
}
@media (max-width: 630px) {
    .contactBody__header-title {
        width: 66%;
    }
}
@media (max-width: 560px) {
    .contactBody__header-title {
        width: 72%;
    }
}
@media (max-width: 506px) {
    .contactBody__header-title {
        width: 78%;
    }
}
@media (max-width: 460px) {
    .contactBody__header-title {
        width: 100%;
	font-size: 48px;
    }
}
@media (max-width: 572px) {
    .contactBody__title {
        margin: 0 auto 30px auto;
        font-size: 25px;
    }
    .contactBody__main {
        padding: 30px 20px 0 20px;
        margin-top: 20px;
    }
}

