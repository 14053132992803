.login {
    height: calc(100vh - 205px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('../../images/phoenix.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    &__title {
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        margin-bottom: 10px;
    }
    &__form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__input {
        margin-right: 7px;
        border-radius: 21px;
        padding: 4px 10px 4px 18px;
        background-color: transparent;
        border: 1px solid #222;
        width: 350px;
        margin-bottom: 10px;
        &::placeholder {
            color: #fff;
        }
    }
    &__enter {
        background-color: #222;
        margin-top: 5px;
        border: none;
        border-radius: 21px;
        color: #fdfbfb;
        min-width: 160px;
        font-weight: 500;
        padding: 5px 9px;
        cursor: pointer;
        transition: all 0.7s ease;
        &:hover {
            background-color: #2E6A9F;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: #f7f4f4;
        }
    }
    &__register {
        position: absolute;
        top: 220px;
        right: 20px;
        background-color: #2E6A9F;
        margin-top: 5px;
        border: 1px #2E6A9F solid;
        border-radius: 21px;
        color: #fdfbfb;
        min-width: 160px;
        font-weight: 500;
        padding: 5px 9px;
        cursor: pointer;
        transition: all 0.7s ease;
        &:hover {
            background-color: #fdfdfd;
            color: #2E6A9F;
        }
    }
}

@media (max-width: 530px){
    .login__title {
        font-size: 40px;
        line-height: 50px;
    }
    .login__register {
        max-width: 120px;
    }
}

@media (max-width: 463px) {
    .login {
        background-size: 100% calc(100vh - 205px);
        background-position: center top;
    }
}
