.post {
    width: 335px;
    margin: 0 25px 40px 25px;
    transition: all 0.7s ease;
    &:hover {
        transform: translateY(-20px);
	.post__img {
		width: 110%;
		height: 260px;
	}
    }
    &__img {
	transition: all 0.7s ease;
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 7px;
    }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    &__cat {
        font-family: "Varela Round", sans-serif;
        font-size: 11px;
        color: #2E6A9F;
        line-height: 20px;
        margin-top: 15px;
        margin-right: 10px;
        cursor: pointer;
    }
    &__title {
        font-size: 20px;
        color: #222;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            color: #555;
        }
    }
    &__date {
        margin-top: -15px;
        font-style: italic;
        font-size: 11px;
        color: #999;
        position: absolute;
        top: 75px;
    }
    &__describtion {
        font-size: 14px;
        color: #444;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
	max-width: 350px;
        -webkit-box-orient: vertical;
    }
}

@media (max-width: 1143px) {
    .post {
        width: 315px;
        margin: 0 25px 40px 25px;

    }
}
@media (max-width: 1090px) {
    .post {
        width: 300px;
        margin: 0 25px 40px 25px;

    }
}
@media (max-width: 1075px) {
    .post {
        width: 43%;
        margin: 0 18px 40px 18px;

    }
}
@media (max-width: 1048px) {
    .post {
        width: 46%;
        margin: 0 18px 40px 18px;

    }
}

@media (max-width: 928px) {
    .post {
        width: 47%;
        margin: 0 10px 40px 10px;

    }
}
@media (max-width: 780px) {
    .post {
        width: 345px;
        margin: 0 auto 40px auto;

    }
}

@media (max-width: 750px) {
    .post {
        width: 100%;
        margin: 0 auto 40px auto;

    }
}

