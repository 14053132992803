html {
    box-sizing: border-box;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

ul[class],
ol[class] {
    padding: 0;
}

body {
    overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    font-size: inherit;
}

ul[class] {
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
}

a {

    text-decoration: none;
}

html,
body {
    height: 100%;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    overflow-x: hidden;
    min-width: 300px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.main {
    flex: 1 0 auto;
}

.footer {
    flex: 0 0 auto;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
    &-fluid {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 25px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Varela Round', sans-serif;
}