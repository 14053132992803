.about__header {
    &-container {
        height: 80%;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
    }
    &-img {
        object-fit: cover;
        width: 100%;
        height: 500px;
        display: inline-block;
        filter: blur(0.2rem);
    }
    &-title {
        white-space:nowrap;
        width: 40%;
        height: 14%;
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        transform: translate(-50%, -50%);
        font-weight: bold;
	text-align: center;
        font-size: 70px;
        line-height: 80px;
        color: #fdfbfb;
    }
}

@media (max-width: 1170px) {
    .about__header-title {
        width: 46%;
    }
}
@media (max-width: 1050px) {
    .about__header-title {
        width: 50%;
        font-size: 60px;
    }
}
@media (max-width: 870px) {
    .about__header-title {
        width: 58%;
    }
}
@media (max-width: 800px) {
    .about__header-title {
        width: 64%;
    }
}
@media (max-width: 680px) {
    .about__header-title {
        width: 70%;
    }
}
@media (max-width: 630px) {
    .about__header-title {
        width: 78%;
    }
}
@media (max-width: 560px) {
    .about__header-title {
        width: 85%;
    }
}
@media (max-width: 506px) {
    .about__header-title {
        width: 92%;
    }
}
@media (max-width: 496px) {
    .about__header-title {
        width: 100%;
        font-size: 50px;
    }
}
@media (max-width: 460px) {
    .about__header-title {
        width: 100%;
    }
}

@media (max-width: 390px) {
    .about__header-title {
	width: 100%;
	font-size: 44px;
    }
}

@media (max-width: 340px) {
    .about__header-title {
        width: 100%;
        font-size: 40px;
    }
}
@media (max-width: 310px) {
    .about__header-title {
        width: 100%;
        font-size: 36px;
    }
}
