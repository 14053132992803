.containerStyles {
    height: 15px;
    width: 90%;
    background-color: "#e0e0de";
    border-radius: 50px;
    margin-top: 5px;
  }

.fillerStyles {
    width: 0;
    height: 100%;
    border-radius: inherit;
    text-align: center;
    position: relative;
    transition: 1s ease 0.3s;
    opacity: 0;
  }

.labelStyles {
    position: absolute;
    margin-top: -10px;
    top: 0;
    bottom: 0;
    padding: 5px;
    color: #222;
    font-weight: bold;
  }