.aboutpage {
    margin-top: 10px;
    &__top{
        margin: 0 30px;
        &-title {
            font-size: 40px;
            color: #222;
            line-height: 55px;
            padding-bottom: 25px;
            position: relative;
            width: 70%;
            margin-bottom: 25px;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                width: 200px;
                height: 2px;
                background-color: #222;
            }
        }
        &-text {
            padding-left: 30%;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 70px;
            }
        }
    }
    &__split {
        position: absolute;
        margin-bottom: 60px;
        height: 400px;
        width: 100%;
        left: 0;
        &-img {
            min-width: 100%;
            object-fit: cover;
            height: 399px;
        }
    }
    &__diagram {
        position: relative;
        margin-top: 550px;
        &::after {
            content: "";
            background-image: url('../../images/bggraph.JPG');
            background-size: cover;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            height: 620px;
            z-index: -5;
        }
        &-title {
            margin: 0 30px;
            font-size: 40px;
                color: #222;
                line-height: 55px;
                padding-bottom: 25px;
                position: relative;
                width: 70%;
                margin-bottom: 25px;
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    width: 200px;
                    height: 2px;
                    background-color: #222;
                }
        }
        &-text {
            margin: 0 30px;
            margin-bottom: 30px;
        }
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 620px;
            &:hover {
                .aboutpage__diagram-container-item-line {
                    animation-play-state: running;
                }
            }
            &-table {
                flex: 8;
                max-width: 100%;
                margin-left: 50px;
            }
            &-item {
                margin-bottom: 20px;
                &-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 10px;
                    width: 90%;
                }
                &-name {
                    font-size: 18px;
                    line-height: 28px;
                    color: #fff;
                }
                &-data {
                    margin-right: 5px;
                }
            }
        }
        &-img {
            flex: 3;
            width: 400px;
            height: 400px;
            object-fit: contain;
        }
    }
    &__posts {
        margin-top: 30px;
        padding: 0 30px;
        width: 100%;
        &-title {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 30px;
            text-align: center;
        }
        &-cont {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}

@media(max-width: 1200px){
    .aboutpage__top-title {
        font-size: 30px;
        line-height: 40px;
    }
    .aboutpage__diagram-title {
        font-size: 30px;
        line-height: 40px;
    }
}
@media(max-width: 945px){
    .aboutpage__top-title {
        font-size: 25px;
        line-height: 35px;
    }
    .aboutpage__diagram-img {
        width: 300px;
        height: 300px;
        flex: 2;
    }
    .aboutpage__diagram-table {
        width: 300px;
        height: 300px;
        flex: 9;
    }
}

@media(max-width: 945px){
    .aboutpage__posts-cont {
        flex-direction: column;
        align-items: center;
    }
}

@media(max-width: 820px){
    .aboutpage__diagram-table {
        max-width: 80%;
    }
}
@media(max-width: 810px){
    .aboutpage__top-title {
        width: 80%;
    }
    .aboutpage__diagram-title {
        width: 80%;
    }
}
@media(max-width: 784px){
    .aboutpage__diagram-container {
        height: 630px;
    }
    .aboutpage__diagram-table {
        margin-top: 30px;
    }
    .aboutpage__top-text {
        padding-left: 15%;
    }
    .aboutpage__diagram-item-content {
        width: 100%;
    }
}
@media(max-width: 740px){
    .aboutpage__top-text {
        padding-left: 15%;
    }
    .aboutpage__diagram-item-content {
        width: 100%;
    }
}
@media(max-width: 729px){
    .aboutpage__diagram-container {
        height: 650px;
    }
    .aboutpage__diagram::after {
        height: 650px;
    }
    .aboutpage__diagram-table {
        margin-top: 50px;
    }
    .aboutpage__top-text {
        padding-left: 15%;
    }
    .aboutpage__diagram-item-content {
        width: 100%;
    }
}
@media(max-width: 710px){
    .aboutpage__top-title {
        width: 90%;
    }
    .aboutpage__diagram-title {
        width: 90%;
    }
    .aboutpage__diagram-img {
        width: 250px;
        height: 250px;
    }
}
@media(max-width: 650px){
    .aboutpage__diagram-container {
        height: 670px;
    }
    .aboutpage__diagram::after {
        height: 670px;
    }
    .aboutpage__diagram-img {
        width: 220px;
        height: 220px;
    }
}
@media(max-width: 637px){
    .aboutpage__top-title {
        width: 100%;
    }
    .aboutpage__diagram-title {
        width: 100%;
    }
}
@media(max-width: 578px){
    .aboutpage__top-title {
        font-size: 20px;
        line-height: 30px;
    }
    .aboutpage__diagram {
        margin-top: 500px;
    }
    .aboutpage__diagram-title {
        font-size: 20px;
        line-height: 30px;
    }
}
@media(max-width: 550px){
    .aboutpage__diagram-img {
        width: 200px;
        height: 200px;
    }
}
@media(max-width: 520px){
    .aboutpage__diagram-container-item-name {
        font-size: 16px;
    }
    .aboutpage__diagram-img {
        width: 180px;
        height: 180px;
    }
}
@media(max-width: 486px){
    .aboutpage__top-title {
        font-size: 18px;
        line-height: 28px;
        &::after {
            width: 150px;
        }
    }
    .aboutpage__diagram-title {
        font-size: 18px;
        line-height: 28px;
        &::after {
            width: 150px;
        }
    }
    .aboutpage__top-text {
        padding-left: 10%;
    }
}
@media(max-width: 458px){
    .aboutpage__diagram-img {
        width: 160px;
        height: 160px;
    }
}


@media(max-width: 400px) {
    .aboutpage__top-title {
        font-size: 16px;
        line-height: 26px;
        &::after {
            width: 140px;
        }
    }
    .aboutpage__diagram-title {
        font-size: 16px;
        line-height: 26px;
        &::after {
            width: 140px;
        }
    }
    .aboutpage__top-text {
        padding-left: 10%;
    }
    .aboutpage__diagram-img {
        width: 150px;
	height: 150px;
}
    .aboutpage__diagram::after {
	height: 660px;
}
.aboutpage__diagram-container-item {
    margin-bottom: 5px;
}
}

@media (max-width: 375px) {
  aboutpage__diagram-container-item:first-child  {
	margin-top: 20px;
}
}
