.write {
    margin: 30px 20px;
    &__rel {
        position: relative;
    }
    &__title {
        position: absolute;
        top: 7%;
        left: 3%;
        width: 41.5%;
        height: 10%;
        font-size: 50px;
        line-height: 60px;
        color: #fdfbfb;
    }
    &__image {
        width: 100%;
        height: 400px;
        border-radius: 10px;
        margin-bottom: 20px;
        object-fit: cover;
    }
    &__form {
        width: 100%;
        margin-bottom: 20px;
        margin: 0 100px;
        &-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
        &-icon {
            width: 30px;
            height: 30px;
            border-radius: 2px;
            margin-right: 20px;
            cursor: pointer;
        }
        &-post-title {
            border:none;
            margin-right: 7px;
            border-radius: 21px;
            padding: 4px 10px 4px 18px;
            background-color: transparent;
            width: 50%;
            margin-bottom: 10px;
            height: 40px;
            &::placeholder {
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 25px;
                line-height: 28px;
                color: #a1a1a1;
            }
        }
        &-textarea {
            margin-right: 7px;
            border-radius: 21px;
            padding: 4px 10px 4px 18px;
            background-color: transparent;
            border: none;
            width: 80%;
            margin-bottom: 10px;
            height: 80vh;
            &::placeholder {
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 17px;
                line-height: 28px;
                color: #a1a1a1;
            }
        }
        &-button {
            background-color: lightseagreen;
            border: none;
            border-radius: 21px;
            color: #fff;
            min-width: 160px;
            font-weight: 500;
            padding: 5px 9px;
            cursor: pointer;
            transition: all 0.7s ease;
            &:hover {
                background-color: #2E6A9F;
                color: #fdfbfb;
            }
        }
	&-links {
            margin: 20px 0;
	    &-title {
                font-size: 25px;
                line-height: 35px;
                margin-bottom: 10px;
                color: #222;
            }
            &-btn {
                background-color: #222;
                border: 1px solid #222;
                border-radius: 21px;
                color: #fdfbfb;
                width: 160px;
                font-weight: 500;
                padding: 5px 9px;
                cursor: pointer;
                transition: all 0.7s ease;
                &:hover {
                    background-color: #2E6A9F;
                    color: #fdfbfb;
                }
            }
            &-container {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            &-name {
		border: 1px solid #000;
                border-radius: 21px;
                padding: 4px 10px 4px 18px;
                background-color: transparent;
                width: 25%;
                margin-right: 10px;
                height: 40px;
                &::placeholder {
                    font-family: "Open Sans", sans-serif;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 28px;
                    color: #a1a1a1;
                }
            }
            &-link {
		border: 1px solid #000;
                border-radius: 21px;
                padding: 4px 10px 4px 18px;
                background-color: transparent;
                width: 25%;
                height: 40px;
		margin-right: 20px;
                &::placeholder {
                    font-family: "Open Sans", sans-serif;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 28px;
                    color: #a1a1a1;
                }
            }
	    &-show {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
		&-name {
                    margin-right: 10px;
                    border: 1px solid #000;
                    border-radius: 21px;
                    padding: 4px 10px 4px 10px;
                }
            }
        }
	&-images {
            margin: 20px 0;
            padding: 10px 0;
            &-title {
                font-size: 25px;
                line-height: 35px;
                margin-bottom: 10px;
                color: #222;
            }
            &-show {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                flex-direction: column;
            }
            &-img {
                width: 100%;
                height: 290px;
                border-radius: 10px;
                object-fit: scale-down;
            }
            &-describtion {
                font-size: 17px;
                line-height: 27px;
            }
            &-add {
                margin: 20px 0;
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: flex-start
            }
        }
    }
    &__drop {
        margin-left: 15px;
        position: relative;
        width: 20%;
        background-color: #fff;
        margin-bottom: 15px;
        &-img {
            width: 17px;
            height: 17px;
            margin-left: 10px;
            transition: all .3s ease;
        }
        &-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            padding: 0 16px;
            border-radius: 12px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        &-list {
            border-radius: 12px;
            margin-top: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            padding: 16px;
            display: none;
        }
        &-item {
            display: flex;
            align-items: center;
            margin-left: 11px;
            transition: all .3s ease;
            padding: 8px 16px;
        }

        &-text {
            font-size: 15px;
            margin-left: 12px;
        }
        &-image {
            width: 18px;
            height: 18px;
            transform: scale(0);
            &-container {
                display: flex;
                border: 1.5px solid #c0c0c0;
                border-radius: 4px;
                cursor: pointer;
                transition: all .3s ease;
            }
        }
    }
}

.write__drop-btn.open ~ .write__drop-list {

        display: block;

}

.write__drop-btn.open .write__drop-img {
    transform: rotate(-180deg);
}

@media (max-width:875px) {
    .write {
        margin: 30px 10px;
    }
    .write__form {
        margin: 0 50px 20px 50px;
    }
}
@media (max-width:720px) {
    .write__image {
        height: 300px;
    }
}
@media (max-width:670px) {
    .write__image {
        height: 250px;
    }
    .write__form {
        margin: 0 40px 20px 40px;
    }
}
@media (max-width:570px) {
    .write__form {
        margin: 0 30px 20px 30px;
    }
}
@media (max-width:535px) {
    .write__form {
        margin: 0 20px 20px 20px;
    }
}
@media (max-width:465px) {
    .write__image {
        height: 225px;
    }
    .write__form {
        margin: 0 10px 20px 10px;
    }
}
