.posts {
    flex: 9;
    &__title {
        text-align: center;
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 40px;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 530px) {
    .posts__title {
	padding-top: 30px;
    }
}

@media (max-width: 356px) {
    .posts__title {
	padding-top: 60px;
    }
}
